<template>
	<div>
		<top-bar
			:phone="defaults[locale].website.phone"
			:email="defaults[locale].website.email"
			:navigation="langmenu"
		/>
		<nav class="main-nav delay fix-on-scroll" :class="{ 'show-mobile-nav': showMobileNav }">
			<div class="row">
				<div class="columns column12">
					<div class="nav-bar">
						<nuxt-link
							class="logo"
							:to="{ path: localePath('/', locale), hash: route.name.startsWith('index') && '#top' }"
						/>
						<div class="nav-content">
							<ul>
								<li class="menu-close" @click="hideMobileNav">
									<font-awesome-icon class="icon" icon="fal fa-times" size="1x" />
								</li>
								<li v-for="item in topmenu[locale]" :key="item.filename" class="nav-item">
									<span @click="hideMobileNav">
										<nuxt-link :to="item.filename">
											{{ item.header }}
										</nuxt-link>
									</span>
									<submenu
										v-if="item.submenu.length"
										:subitems="item.submenu"
										@hide-mobile-nav="hideMobileNav"
									/>
								</li>
								<!--
								<li class="lang-nav mobile-only">
									<div v-for="item in langmenu" :key="item.filename" class="language">
										<nuxt-link
											class="flag"
											:class="item.language"
											:to="item.filename"
											@click="hideMobileNav"
										>
											<span>{{ $t(item.language) }}</span>
										</nuxt-link>
									</div>
								</li>
								-->
							</ul>
						</div>
						<div class="buttons">
							<div class="menu-button button mobile-only" @click="toggleMobileNav">
								<font-awesome-icon class="icon" icon="fal fa-bars" size="1x" />
								<span>{{ $t('buttonMenu') }}</span>
							</div>
							<a
								class="button phone mobile-only"
								:href="`tel:${defaults[locale].website.phone}`"
								aria-label="Call us"
							>
								<font-awesome-icon class="icon" icon="fal fa-phone" size="1x" />
								<span>{{ $t('buttonCall') }}</span>
							</a>
							<book-button class="button book-now cta reserve-now">
								<font-awesome-icon class="icon" icon="fal fa-calendar-alt" size="1x" />
								<span>{{ $t('buttonMakeReservation') }}</span>
							</book-button>
						</div>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();
const route = useRoute();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}

const showMobileNav = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const langmenu = computed(() => {
	return (page.value && page.value.langmenu) ?? [];
});

const toggleMobileNav = () => {
	showMobileNav.value = !showMobileNav.value;
};

const hideMobileNav = () => {
	showMobileNav.value = false;
};
</script>

<style lang="scss" scoped>
@media (min-width: 1201px) {
	.nav-item:hover :deep(.submenu ul) {
		display: block;
	}
}

.mobile-only {
	display: none !important;
}

.main-nav {
	position: absolute;
	top: 42px;
	left: 0;
	right: 0;
	z-index: 1000;
	transition: background 0.3s ease-in-out;
	border-bottom: 1px solid #fff;

	.nav-content ul {
		display: inline-flex;
	}

	&.fixed {
		position: fixed;
		top: 0;

		.logo {
			margin: 10px 0;
		}
	}

	ul li {
		display: inline-block;
		padding: 5px 0;
	}
}

.nav-bar {
	display: flex;
	align-items: center;
	padding: 3px 0;
	z-index: 9;
	position: relative;

	.buttons {
		display: flex;
		align-items: center;
		gap: 10px;

		.button {
			padding: 15px 25px;
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-flow: row nowrap;

			&.book-now {
				padding-left: 20px;
				min-width: 220px;
			}
		}

		.icon {
			margin-right: 13px;
			font-size: 14px;
		}
	}
}

.menu-button {
	cursor: pointer;
}

.logo {
	padding: 0;
	text-decoration: none;
	margin: -22px 0 20px;
	width: 122px;
	height: 103px;
	background: url('~/assets/images/logo-hotel-thesixteen-full-white.png') no-repeat center center;
	background-size: 122px;
	transition: all 0.3s ease-in-out;
}

.nav-content {
	margin: auto;

	a {
		color: #fff;
		text-decoration: none;
		text-transform: uppercase;
		margin: 0 15px;
		letter-spacing: 2px;
		padding: 0 2px 2px;
		font-weight: 600;
		transition: border 0.3s ease-in-out;
		font-size: 15px;
		border-bottom: 3px solid rgba(0 0 0 / 0%);

		&.active,
		&:hover {
			color: #fff;
			border-color: #fff;
		}
	}

	.menu-close {
		color: #fff;
		display: none;
		font-size: 30px;
		cursor: pointer;
		position: absolute;
		top: 15px;
		width: auto;
		right: 20px;
		padding: 0;
		margin: 0;
	}

	.lang-nav {
		margin: 25px auto;
		padding: 15px 0 0;
		border-top: 1px solid #6d6d6d;
		display: inline-block;
		max-width: 400px;

		.language {
			margin: 20px 0;
		}

		a {
			font-size: 15px;
			color: #fff;

			&.router-link-exact-active,
			&:hover {
				color: var(--cta-color);
			}
		}

		img {
			max-width: 16px;
			margin: -2px 5px 0 0;
			vertical-align: middle;
		}
	}
}

@media (max-width: 1280px) {
	.nav-bar a {
		font-size: 14px;
	}
}

@media (max-width: 1200px) {
	.mobile-only {
		display: flex !important;

		&.lang-nav {
			display: inline-block !important;
			max-width: 100%;
		}
	}

	.nav-bar .buttons .icon {
		display: unset;
	}

	.main-nav {
		position: fixed !important;
		background: rgb(109 115 111);
		top: 0;
		box-shadow: 0 0 10px rgb(0 0 0 / 5%);

		&.show-mobile-nav {
			.nav-content ul {
				display: flex;
				flex-direction: column;
				justify-content: center;
				opacity: 1;
				visibility: visible;
				transition: opacity 0.3s ease-in-out;

				li:not(.menu-close) {
					width: 100%;
				}

				li:not(.lang-nav, .menu-close) {
					margin: 7px 0;

					a {
						font-size: 20px;
					}
				}
			}
		}

		.logo {
			margin: 10px 0 !important;
		}

		ul {
			position: fixed;
			inset: 0;
			background: rgba(109 115 111 / 90%);
			text-align: left;
			padding: 20px 0;
			opacity: 0;
			visibility: hidden;
			overflow-y: scroll;
		}
	}

	.nav-content .menu-close {
		display: block;
	}
}

@media (max-width: 880px) {
	.nav-bar .buttons .button {
		padding: 16px;

		.icon {
			margin-right: 0;
			font-size: 16px;
		}

		&.book-now {
			padding-left: 16px;
			min-width: auto;
		}

		span {
			display: none;
		}
	}
}

@media (max-width: 580px) {
	.nav-bar .buttons span {
		display: none;
	}
}
</style>
